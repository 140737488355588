import '@/polyfills'
import { newVue } from '@/config/vue'
import router from '@/router'
import Footer from './Footer.vue'

document.addEventListener('DOMContentLoaded', () => {
  newVue({
    router,
    render: h => h(Footer),
    mount: '.js-footer',
  })
})
