export default {
  namespaced: true,

  state: {
    orderNumber: '',
    email: '',
    returnData: {},
    orderData: {},
  },

  mutations: {
    setOrderNumber(state, orderNumber) {
      state.orderNumber = orderNumber
    },

    setEmail(state, email) {
      state.email = email
    },

    setReturnData(state, returnData) {
      state.returnData = { ...state.returnData, ...returnData }
    },

    setOrderData(state, orderData) {
      state.orderData = { ...state.orderData, ...orderData }
    },
  },
}
