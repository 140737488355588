// Documentation: https://docs.rollbar.com/docs/vue-js

import Vue from 'vue'
import VueRollbar from 'vue-rollbar'
import { DOMAIN, CUSTOMER_SERVICE_SUBDOMAIN } from './paths'

let enabled = false
let errorsNotSent = []

const UNKNOWN_ERROR_MESSAGE = 'Unknown Error'

const ignoredErrorMessages = [
  // this will catch errors like "t: Network error: undefined" as they are not caught by ignoredMessages
  /Network error/i,
  // @see: https://github.com/getsentry/sentry-javascript/issues/3040
  // @see: https://github.com/mozilla-mobile/firefox-ios/issues/5818
  /window\.webkit\.messageHandlers\.selectedDebugHandler\.postMessage/i,
]
const botsUserAgentIDs = ['Pinterestbot']

export const isErrorMessageIgnored = args =>
  Object.values(args || {}).some(error =>
    ignoredErrorMessages.some(pattern => pattern.test(error?.message))
  )

export const isBot = () =>
  botsUserAgentIDs.some(ua => (window.navigator.userAgent || '').includes(ua))

Vue.config.errorHandler = function (err) {
  enabled ? Vue.rollbar.error(err.message || UNKNOWN_ERROR_MESSAGE, err) : errorsNotSent.push(err)
  console.error(err) // eslint-disable-line no-console
}

Vue.use(VueRollbar, {
  accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled,
  environment: process.env.TARGET_ENV,
  hostSafeList: [DOMAIN, CUSTOMER_SERVICE_SUBDOMAIN],
  ignoredMessages: [
    /Script error/i,
    /Network error/i, // this did not catch all messages (e.g. "t: Network error: undefined")
    /Network connection/i,
    /The operation was aborted/i,
  ],
  checkIgnore: (isUncaught, args = {}) => {
    // @see: https://docs.rollbar.com/docs/reduce-noisy-javascript-errors
    return isBot() || isErrorMessageIgnored(args)
  },
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.ROLLBAR_CODE_VERSION,
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
})

function enableVueRollbar(checkIgnore) {
  enabled = true
  const config = { enabled }
  if (checkIgnore) config.checkIgnore = checkIgnore
  Vue.rollbar.configure(config)

  errorsNotSent.forEach(err => Vue.rollbar.error(err.message || UNKNOWN_ERROR_MESSAGE, err))
  errorsNotSent = []
}

window.On = window.On || {}
window.On.enableVueRollbar = enableVueRollbar
