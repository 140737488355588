<template>
  <div id="app" class="app" :class="{ 'page-not-ready': !isPageReady }">
    <!-- Initial page load -->
    <transition-group name="fade-site">
      <!--
        Extra div to avoid putting the key on a dynamically imported element (layoutComponent)
        which can lead to reactivity issues from the transition-group
      -->
      <div v-show="isInitiallyLoaded" key="component">
        <component :is="layoutComponent">
          <!-- Transition from one StandardView to another -->
          <transition name="fade-site" mode="out-in">
            <router-view :key="$route.path" />
          </transition>
        </component>
      </div>
      <LoadingIndicator
        v-if="!isInitiallyLoaded"
        key="loader"
        class="app__loading-indicator"
        v-data-test-id="'app-loading-indicator'"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageService from '@/api/services/_shared/page-service'
import LoadingIndicator from '@/components/_shared/LoadingIndicator.vue'
import { waitFor } from '@/helpers/time'

export default {
  name: 'App',
  components: {
    LoadingIndicator,
    DefaultLayout: () =>
      import(/* webpackChunkName: "DefaultLayout" */ '@/layouts/DefaultLayout.vue'),
  },

  data() {
    return {
      isInitiallyLoaded: false,
    }
  },

  computed: {
    ...mapState('page', ['isPageReady', 'sideNavGroups']),

    layoutComponent() {
      return 'DefaultLayout'
    },
  },

  created() {
    PageService.fetchSideNavAndCurrentPageEntry()

    /**
     * Will show the LoadingIndicator of App.vue only on the first page load and will show the
     * LoadingIndicator of ViewContainer when switching pages to make sure we keep the hero
     * section and the side navigation visible while loading the next page (to avoid jumps).
     * That's why we show the LoadingIndicator here based on "this.isInitiallyLoaded" and not
     * "this.isPageReady".
     */
    waitFor(this, () => this.isPageReady).then(() => (this.isInitiallyLoaded = true))

    // for debugging purposes only (i.e. access the store, router, api client)
    if (process.env.NODE_ENV === 'development') window.APP = this
  },
}
</script>

<style lang="scss">
// global styles
.fade-site {
  &-enter-active {
    transition: opacity $transition-time-slow;

    .page-not-ready & {
      transition: none;
    }
  }

  &-leave-active {
    transition: opacity $transition-time-fast;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
.app {
  &__loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
