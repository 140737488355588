import { t } from '@/config/vue-i18n'
import store from '@/config/vuex'
import { getLocale } from '@/helpers/locale'

export default {
  namespaced: true,
  state: {
    country: '',
    currentPath: '',
    isPageReady: false,
    isSideNavReady: false,
    language: '',
    locale: '',
    metaTitle: '',
    phoneNumber: '',
    serviceHours: '',
    sideNavGroups: [],
    subtitle: '',
    title: '',
  },

  mutations: {
    setContactInfo(state, currentStore) {
      state.serviceHours = currentStore.serviceHours
      state.phoneNumber = currentStore.phoneNumber
    },

    setSideNavGroups(state, sideNavGroups) {
      if (sideNavGroups) state.sideNavGroups = sideNavGroups
    },

    setSideNavReadyState(state, isReady) {
      state.isSideNavReady = isReady
    },

    setPageEntryProperties(state, payload) {
      state.title = payload.title || ''
      state.subtitle = payload.subtitle || ''
      state.metaTitle = payload.metaTitle || ''
    },

    setPageProperties(state) {
      const { locale, language, country } = getLocale()
      state.locale = locale
      state.language = language
      state.country = country
    },

    setPageReadyState(state, isReady) {
      state.isPageReady = isReady
    },

    setCurrentPath(state, payload) {
      state.currentPath = payload.currentPath
    },
  },

  actions: {
    updatePageEntryProperties({ commit }, payload) {
      const pageData = payload || {}
      commit('setPageEntryProperties', pageData)
      commit('setPageReadyState', true)
      const pageTitle = pageData.metaTitle ? `${pageData.metaTitle} | ` : ''
      document.title = pageTitle + t('layouts.application.title')
    },
  },

  getters: {
    dateLocale: state =>
      state ? state.locale.substring(0, 3) + state.locale.substring(3).toUpperCase() : undefined,

    isHomePage: state => state.currentPath === '/',
  },
}

// Page properties have to be set *before* any other DOMContentLoaded handlers run since some
// of them rely on the page store (e.g. to build GraphQL clients). This is why this complicated
// scheduling is in place below (readystatechange fires before DOMContentLoaded).
document.addEventListener('readystatechange', function () {
  if (document.readyState !== 'loading') store.commit('page/setPageProperties')
})
