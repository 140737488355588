export const DOMAIN = 'on-running.com'
export const CUSTOMER_SERVICE_SUBDOMAIN = `customer-service.${DOMAIN}`
export const DEALERS_PATH = 'dealers'
export const SHOP_PATH = 'shop'
export const TERMS_AND_CONDITIONS = 'terms_and_conditions'
export const PRIVACY_PATH = 'privacy_policy'
export const ACCESSIBILITY_PATH = 'accessibility'
export const COMPANY_INFORMATION_PATH = 'company_information'
export const VULNERABILITY_REPORTING_URL = 'https://hackerone.com/on'
export const ORDER_STATUS_PATH = 'order-status'
export const ORDER_TRACKER_PATH = 'order-tracker'
export const CUSTOMER_SERVICE_CONTACT_US_PATH = 'contact_us'
export const DOMAIN_CONTACT_US_PATH = 'contact-us'
export const CUSTOMER_SERVICE_WARRANTY_CLAIMS_PATH = 'warranty-claims'
export const DOMAIN_WARRANTY_CLAIMS_PATH = 'warranty-claims'
