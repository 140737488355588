import store from '@/config/vuex'

let debounceTimer = null

export const getWindowProperties = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    dimensions: `${window.innerWidth}x${window.innerHeight}`,
  }
}
export default {
  namespaced: true,
  state: getWindowProperties(),

  mutations: {
    setSize(state, payload) {
      state.width = payload.width
      state.height = payload.height
      state.dimensions = payload.dimensions
    },
  },

  getters: {
    isMqTiny: state => state.width < On.MediaQueries.width.small,
    isMqSmall: state => state.width >= On.MediaQueries.width.small,
    isMqMedium: state => state.width >= On.MediaQueries.width.medium,
    isMqLarge: state => state.width >= On.MediaQueries.width.large,
    isMqLarger: state => state.width >= On.MediaQueries.width.larger,
    isMqFull: state => state.width >= On.MediaQueries.width.full,
    isMqHigh: state => state.width >= On.MediaQueries.width.high,
    isMqHigher: state => state.width >= On.MediaQueries.width.higher,
    isMqCinema: state => state.width >= On.MediaQueries.width.cinema,
  },
}

window.addEventListener('resize', () => {
  clearTimeout(debounceTimer)
  debounceTimer = setTimeout(() => store.commit('viewport/setSize', getWindowProperties()), 100)
})
