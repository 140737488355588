import Vue from 'vue'
import store from './vuex'
import { getI18nInstance } from './vue-i18n'
import DataTestIdDirective from '@/directives/data-test-id'
import { NotifyPlugin } from '@/services/notify'
import { setupDynamicYieldClientListeners } from './dynamic-yield'
import './rollbar'

Vue.use(NotifyPlugin)
Vue.directive('data-test-id', DataTestIdDirective)

setupDynamicYieldClientListeners()

function newVue(options) {
  if (!document.querySelector(options.mount)) return

  const vueOptions = {
    render: options.render,
    store,
    router: options.router,
    i18n: getI18nInstance(),
  }
  if (options?.props) vueOptions.props = options?.props

  const vm = new Vue(vueOptions)
  vm.$mount(options.mount)

  return vm
}

export { Vue, newVue }
