<template>
  <div v-show="isVisibleInternal">
    <svg
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 48 48"
      width="48"
      height="48"
      class="svg"
    >
      <circle cx="50%" cy="50%" r="22" vector-effect="non-scaling-stroke" class="circle"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',

  props: {
    /**
     * Toggles visibility.
     */
    isVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * Makes it show up only after "displayDelay" has passed.
     */
    hasDelayedDisplay: {
      type: Boolean,
      default: false,
    },
    /**
     * Is only applied if "hasDelayedDisplay" is set to "true".
     */
    displayDelay: {
      type: Number,
      default: 700,
    },
  },

  data() {
    return {
      isVisibleInternal: true,
      displayDelayTimeoutId: null,
    }
  },

  watch: {
    isVisible: {
      immediate: true,
      handler() {
        if (this.isVisible && this.hasDelayedDisplay) {
          this.displayDelayTimeoutId = setTimeout(() => {
            this.isVisibleInternal = true
          }, this.displayDelay)
        } else {
          clearTimeout(this.displayDelayTimeoutId)
          this.isVisibleInternal = this.isVisible
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.svg {
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}
.circle {
  fill: none;
  stroke: $color-black;
  stroke-dashoffset: 0;
  stroke-linecap: square;
  stroke-linejoin: square;
  transform-origin: center;
  --diameter-long: 123.3;
  --diameter-short: 13.7;
  stroke-width: 4;
  stroke-dasharray: 137;
  animation: indeterminate-spinner 1.5s ease-in-out infinite;
}

@keyframes indeterminate-spinner {
  0% {
    stroke-dashoffset: var(--diameter-long, 120.6);
  }

  50% {
    stroke-dashoffset: var(--diameter-short, 13.4);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: var(--diameter-long, 120.6);
    transform: rotate(360deg);
  }
}
</style>
