import store from '@/config/vuex'

async function waitForStoreValueToBeTrue(getter) {
  let resolvePromise
  const promise = new Promise(resolve => (resolvePromise = resolve))

  const handler = storeValue => {
    if (storeValue) resolvePromise(true)
  }
  const options = { immediate: true }
  const unwatch = store.watch(getter, handler, options)
  const storeValue = await promise
  unwatch()

  return storeValue
}

export async function waitForSideNavToBeReady() {
  return await waitForStoreValueToBeTrue(state => state.page.isSideNavReady)
}
