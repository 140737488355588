<template ref="footer">
  <footer class="footer-bottom">
    <div class="footer-bottom__container">
      <div class="footer-bottom__year">{{ `© On ${new Date().getFullYear()}` }}</div>
      <div class="footer-bottom__lists">
        <ul class="footer-bottom__list">
          <li>
            <a :href="`https://${CUSTOMER_SERVICE_SUBDOMAIN}/${TERMS_AND_CONDITIONS}`">{{
              $t('terms_conditions')
            }}</a>
          </li>
          <li>
            <a :href="`https://${CUSTOMER_SERVICE_SUBDOMAIN}/${PRIVACY_PATH}`">{{
              $t('privacy_policy')
            }}</a>
          </li>
          <li>
            <a :href="`https://${CUSTOMER_SERVICE_SUBDOMAIN}/${ACCESSIBILITY_PATH}`">{{
              $t('accessibility')
            }}</a>
          </li>
          <li>
            <a :href="`https://${CUSTOMER_SERVICE_SUBDOMAIN}/${COMPANY_INFORMATION_PATH}`">{{
              $t('imprint')
            }}</a>
          </li>
          <li>
            <a :href="`${VULNERABILITY_REPORTING_URL}`" target="_blank">{{
              $t('vulnerability_reporting')
            }}</a>
          </li>
          <li>
            <a id="ot-sdk-btn" class="optanon-toggle-display" href="javascript:void(0)">
              {{ $t('consent_settings') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import {
  CUSTOMER_SERVICE_SUBDOMAIN,
  TERMS_AND_CONDITIONS,
  PRIVACY_PATH,
  ACCESSIBILITY_PATH,
  COMPANY_INFORMATION_PATH,
  VULNERABILITY_REPORTING_URL,
} from '@/config/paths.js'

export default {
  name: 'Footer',

  data() {
    return {
      CUSTOMER_SERVICE_SUBDOMAIN,
      TERMS_AND_CONDITIONS,
      PRIVACY_PATH,
      ACCESSIBILITY_PATH,
      COMPANY_INFORMATION_PATH,
      VULNERABILITY_REPORTING_URL,
    }
  },
}
</script>

<style lang="scss" scoped>
.footer-bottom {
  position: relative;
  width: 100%;
  bottom: 0;
  background: $color-black;
  color: $color-white;

  &__container {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    margin: auto;

    @media (min-width: $mq-larger) {
      flex-direction: row;
      align-items: center;
      padding: 40px 64px;
    }
  }

  &__year {
    font-weight: 500;
    font-size: $font-size-small;
    line-height: 1.5;

    @media (min-width: $mq-larger) {
      font-size: $font-size-normal;
      line-height: 1.71;
      width: 120px;
    }
  }

  &__lists {
    padding-top: 20px;

    @media (min-width: $mq-larger) {
      padding: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    li {
      padding-bottom: 7px;

      a {
        &:hover,
        &:focus,
        &:active {
          color: $color-white;
        }
      }

      @media (min-width: $mq-larger) {
        padding-left: 32px;
        padding-bottom: 0;
        width: min-content;
      }

      @media (min-width: $mq-full) {
        width: initial;
      }

      &:last-child {
        padding-bottom: 60px;

        @media (min-width: $mq-larger) {
          padding-bottom: 0;
        }
      }
    }

    @media (min-width: $mq-larger) {
      flex-direction: row;
    }
  }
}
</style>

<i18n src="@/locales/footer.json" />
