export function getCookie(key) {
  const name = key + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.includes(name)) return cookie.substring(name.length, cookie.length)
  }
  return ''
}

export function setCookie(key, value, expirationDate) {
  const cookieStr = `${key}=${value};path=/`
  const expirationStr = expirationDate ? `;expires=${expirationDate.toGMTString()}` : ''

  return (document.cookie = cookieStr + expirationStr)
}
