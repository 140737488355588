import Vue from 'vue'
import Vuex from 'vuex'
import flashStore from '@/stores/flash'
import pageStore from '@/stores/page'
import viewportStore from '@/stores/viewport'
import returnStore from '@/stores/return'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    // Global stores only. Use "registerStores" helper otherwise.
    flash: flashStore,
    page: pageStore,
    viewport: viewportStore,
    return: returnStore,
  },
})

export default store
