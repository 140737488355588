import ROUTE_PATHS from '@/router/route-paths'
import ROUTE_NAMES from '@/router/route-names'

function importStandardView() {
  return import(/* webpackChunkName: "standard-view" */ '@/views/StandardView.vue')
}

export default [
  {
    path: '/',
    name: ROUTE_NAMES.home,
    component: importStandardView,
  },
  {
    path: ROUTE_PATHS.faq,
    name: ROUTE_NAMES.faq,
    component: importStandardView,
    alias: '/',
  },
  {
    path: `${ROUTE_PATHS.faq}/:slug`,
    name: ROUTE_NAMES.faqDetail,
    component: () => {
      return import(/* webpackChunkName: "faq-detail" */ '@/views/FaqDetail.vue')
    },
  },
  {
    path: ROUTE_PATHS.returnsEdit,
    name: ROUTE_NAMES.returnsEdit,
    component: () => {
      return import(/* webpackChunkName: "returns-edit" */ '@/views/Returns/ReturnsEdit.vue')
    },
  },
  {
    path: ROUTE_PATHS.returnsDirect,
    redirect: { name: ROUTE_NAMES.returnsNew },
  },
  {
    path: ROUTE_PATHS.returnsNew,
    name: ROUTE_NAMES.returnsNew,
    component: () => {
      return import(/* webpackChunkName: "returns-new" */ '@/views/Returns/ReturnsNew.vue')
    },
  },
  {
    path: '/size_guide',
    name: ROUTE_NAMES.sizeGuide,
    component: () => {
      return import(/* webpackChunkName: "sizing" */ '@/views/Sizing.vue')
    },
  },
  {
    path: '/warranty_claims/new',
    redirect: { name: ROUTE_NAMES.warrantyClaim },
  },
  {
    path: '/warranty-claims',
    component: () => {
      return import(/* webpackChunkName: "warranty-claims" */ '@/views/WarrantyClaims.vue')
    },
    children: [
      {
        path: '',
        name: ROUTE_NAMES.warrantyClaim,
        component: () => {
          return import(
            /* webpackChunkName: "warranty-claims-auth" */ '@/views/WarrantyClaims/WarrantyClaimsAuth.vue'
          )
        },
      },
      {
        path: 'retailer',
        name: ROUTE_NAMES.warrantyClaimRetailer,
        component: () => {
          return import(
            /* webpackChunkName: "warranty-claims-identification" */ '@/views/WarrantyClaims/WarrantyClaimsIdentification.vue'
          )
        },
      },
      {
        path: 'selection',
        name: ROUTE_NAMES.warrantyClaimItemsSelection,
        component: () => {
          return import(
            /* webpackChunkName: "warranty-claims-item-selection" */ '@/views/WarrantyClaims/WarrantyClaimsItemsSelection.vue'
          )
        },
      },
      {
        path: 'new',
        name: ROUTE_NAMES.warrantyClaimNew,
        component: () => {
          return import(
            /* webpackChunkName: "warranty-claims-new" */ '@/views/WarrantyClaims/WarrantyClaimsNew.vue'
          )
        },
      },
      {
        path: 'confirmation',
        name: ROUTE_NAMES.warrantyClaimConfirmation,
        component: () => {
          return import(
            /* webpackChunkName: "warranty-claims-confirmation" */ '@/views/WarrantyClaims/WarrantyClaimsConfirmation.vue'
          )
        },
      },
      {
        path: '*',
        beforeEnter: (to, from, next) => next({ name: ROUTE_NAMES.warrantyClaim }),
      },
    ],
  },
  {
    path: '*',
    component: importStandardView,
  },
]
