/**
 * Fetches an event file
 * @param {String} event
 */
const loadEventFile = event =>
  import(/* webpackChunkName: "notify-event-[request]" */ `./_events/${pascalToSnakeCase(event)}`)

/**
 * <= myEventName
 * => my-event-name
 * @param {String} str
 */
const pascalToSnakeCase = str =>
  str
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase()

/**
 * Loads the event file, executes the passed action with the passed parameters
 *
 * e.g:
 * this.$notify({
 *   warrantyClaim: {
 *     timeSpent: {
 *       time: '15 minutes'
 *     }
 *   }
 * })
 * @param {Object} {<eventName>: <actionName>: {<parameters>} }
 */
const notifier = async notifications => {
  for (const eventName in notifications) {
    try {
      const { default: event } = await loadEventFile(eventName)

      for (const actionName in notifications[eventName]) {
        const action = event[actionName]
        const params = notifications[eventName][actionName]

        action(params)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`[$notify] Could not find "${eventName}" event.`)
    }
  }
}

/**
 * Installs $notify plugin
 * - Exposes notifier()
 */
export const NotifyPlugin = {
  install(Vue) {
    Vue.prototype.$notify = notifier
  },
}
