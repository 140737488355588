import Vue from 'vue'
import gql from 'graphql-tag'
import { print as stringifyTag } from 'graphql/language/printer'
import to from 'await-to-js'
import router, { getAppBase } from '@/router'
import store from '@/config/vuex'
import client from '@/api/client'
import SIDE_NAVIGATION_FRAGMENT from '@/api/gql/_fragments/Query/side-navigation.gql'
import ROUTE_PATHS from '@/router/route-paths'
import { resetCsrfToken } from '@/config/csrf-token'
import { waitForSideNavToBeReady } from '@/helpers/events'

const cache = {
  pageData: {},
  pageFragments: {},
}
let currentPageFetchingId = 0
let latestCurrentPageFetchingId = 0

// The id is meant to be re-usable for any pages that's why it is a String which is more versatile
const PAGE_QUERY_ARGUMENTS = { path: '$path: String!', id: '$id: String' }

async function fetchSideNavAndCurrentPageEntry() {
  const pathForQuery = getPagePathForQuery()
  const idForQuery = getIdForQuery(pathForQuery)
  const pageFragment = await getPageFragment(pathForQuery)
  const args = getPageQueryArguments({ path: pathForQuery, id: idForQuery })
  const query = gql`
    query(${args}) {
      ...sideNavigation
      ...pageQuery
    }
    ${stringifyTag(SIDE_NAVIGATION_FRAGMENT)}
    ${pageFragment}
  `
  const variables = { path: pathForQuery, id: idForQuery }
  const [error, response] = await to(client.query({ query, variables }))
  if (!error) {
    store.commit('page/setSideNavGroups', response.data.sideNavGroups)
    store.commit('page/setContactInfo', response.data.currentStore)
    delete response.data.sideNavGroups
    setPageQueryCache(response.data, pathForQuery)
    store.commit('page/setSideNavReadyState', true)
  } else {
    Vue.rollbar.error('Fetching sideNavigation and pageQuery failed.', error)
  }
  return response?.data
}

async function fetchCurrentPageData() {
  return await fetchPageData()
}

async function fetchPageData({ path, isFetchingCurrentPage = true, id } = {}) {
  if (isFetchingCurrentPage) {
    currentPageFetchingId = ++latestCurrentPageFetchingId
    if (!store.state.page.isSideNavReady) await waitForSideNavToBeReady()
    if (currentPageFetchingId !== latestCurrentPageFetchingId) return
  }
  return proceedWithPageDataFetch({ path, isFetchingCurrentPage, id })
}

async function proceedWithPageDataFetch({ path, isFetchingCurrentPage, id }) {
  const formattedPath = path ? formatPath(path) : getCurrentPagePath()
  const pathForQuery = getPagePathForQuery(formattedPath)
  const cachedData = getPageQueryCache(pathForQuery)
  let data

  if (cachedData) {
    data = cachedData
  } else {
    if (isFetchingCurrentPage) store.commit('page/setPageReadyState', false)
    const idForQuery = id !== undefined ? id : getIdForQuery(pathForQuery)
    const query = await getPageQuery(pathForQuery, idForQuery)
    const variables = { path: pathForQuery, id: idForQuery }
    const [error, response] = await to(client.query({ query, variables }))
    if (error) {
      Vue.rollbar.error('Fetching pageQuery failed.', error)
      return undefined
    }
    data = response?.data
    setPageQueryCache(data, pathForQuery)
  }
  if (isFetchingCurrentPage && currentPageFetchingId === latestCurrentPageFetchingId) {
    store.dispatch('page/updatePageEntryProperties', data.page)
  }
  resetCsrfToken()
  resolveRouteFromPageData(data, pathForQuery)
  return data
}

function getCurrentPagePath() {
  const safePathName = window.location.pathname.split('?')[0]
  return formatPath(safePathName.replace(new RegExp(`^${getAppBase()}`), '/'))
}

function getPagePathForQuery(path) {
  let pagePath = path || getCurrentPagePath()

  // In FaqDetail, re-use the FAQ page data that was cached or fetch all FAQs to populate
  // the cache and avoid a second api call when going to the FAQ page
  if (pagePath === '/' || /^\/faq\/\w/.test(pagePath)) pagePath = ROUTE_PATHS.faq
  if (/^\/returns\/.+\/edit/.test(pagePath))
    pagePath = ROUTE_PATHS.returnsEdit.replace('hash', 'id')
  if (/^\/warranty-claims\/\w+/.test(pagePath)) pagePath = ROUTE_PATHS.warrantyClaims

  return pagePath
}

function getIdForQuery(pathForQuery) {
  const { params, query } = router.currentRoute || {}
  if (params?.id) return params.id
  if (query?.id) return query.id

  const getters = {}
  if (getters[pathForQuery]) return getters[pathForQuery]()
}

async function getPageFragment(pagePath) {
  const queryFileName = pagePath.replace(/^\/?/, '').replace(/[^a-z]+/g, '-')
  if (cache.pageFragments[queryFileName]) return cache.pageFragments[queryFileName]

  // First check if page has a specific page fragment (i.e. contact-us.gql)
  let [error, fragment] = await to(importPageFragment(queryFileName))
  // If none has been found, fallback to standard-view.gql
  if (error) {
    Vue.rollbar.error(`Fetching page "${queryFileName}" fragments failed`, error)

    const [defaultError, defaultFragment] = await to(importPageFragment('standard-view'))

    if (defaultError) {
      Vue.rollbar.error('Fetching page "standard-view" fragments failed', error)
      return null
    }

    fragment = defaultFragment
  }

  return (cache.pageFragments[queryFileName] = stringifyTag(fragment.default))
}

function importPageFragment(filename) {
  return import(
    /* webpackChunkName: "[request]" */ `@/api/gql/_fragments/Query/_pages/${filename}.gql`
  )
}

async function getPageQuery(pathForQuery, idForQuery) {
  const pageFragment = await getPageFragment(pathForQuery)
  const args = getPageQueryArguments({ path: pathForQuery, id: idForQuery })
  return gql`
    query(${args}) {
      ...pageQuery
    }
    ${pageFragment}
  `
}

function getPageQueryArguments(args) {
  let queryArguments = []
  for (const key in args) {
    if (args[key] !== undefined && PAGE_QUERY_ARGUMENTS[key]) {
      queryArguments.push(PAGE_QUERY_ARGUMENTS[key])
    }
  }
  return queryArguments.join(', ')
}

function updateCurrentPageQueryCache(data) {
  const cacheKey = getPageCacheKey()
  cache.pageData[cacheKey] = { ...(cache.pageData[cacheKey] || {}), ...data }
}

function setPageQueryCache(data, pathForQuery) {
  const cacheKey = getPageCacheKey(pathForQuery)
  cache.pageData[cacheKey] = data
}

function getPageQueryCache(pathForQuery) {
  const cacheKey = getPageCacheKey(pathForQuery)
  return cache.pageData[cacheKey]
}

function getPageCacheKey(pathForQuery) {
  return pathForQuery || getPagePathForQuery()
}

function formatPath(path) {
  return removeSlashAtTheEnd(ensureLeadingSlash(path))
}

function resolveRouteFromPageData(data, pathForQuery) {
  const pathFromResponse = data?.page?.path
  if (pathFromResponse && pathForQuery && getPagePathForQuery(pathFromResponse) !== pathForQuery) {
    router.replace({ path: pathFromResponse })
    setPageQueryCache(data, pathFromResponse)
  }
}

function ensureLeadingSlash(path) {
  return path.replace(/^\/?/, '/')
}

function removeSlashAtTheEnd(path) {
  return path !== '/' ? path.replace(/\/$/, '') : path
}

const PageService = {
  fetchSideNavAndCurrentPageEntry,
  fetchCurrentPageData,
  fetchPageData,
  getCurrentPagePath,
  updateCurrentPageQueryCache,
}
export default PageService
