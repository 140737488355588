import '@/polyfills'
import { newVue } from '@/config/vue'
import router from '@/router'
import App from '@/App.vue'

document.addEventListener('DOMContentLoaded', () => {
  newVue({
    router,
    render: h => h(App),
    mount: '.js-spa',
  })
})
