/**
 * @return {Object} { locale: 'en-us', language: 'en', country: 'de' }
 */
export function getLocale() {
  let locale, language, country

  if (document?.body?.dataset?.locale) {
    // from <body>
    locale = document.body.dataset.locale
    language = document.body.dataset.language
    country = document.body.dataset.country
  } else {
    // from URL
    const match = window.location.pathname.match(/\/?([a-z]{2})-([a-z]{2})/)
    if (match && match[1]) {
      language = match[1]
      country = match[2]
      locale = `${language}-${country}`
    }
  }

  return { locale, language, country }
}
