export default {
  home: 'home',
  faq: 'faq',
  faqDetail: 'faq-detail',
  returnsNew: 'returns-new',
  returnsEdit: 'returns-edit',
  sizeGuide: 'size-guide',
  warrantyClaim: 'warranty-claims',
  warrantyClaimRetailer: 'warranty-claims-retailer',
  warrantyClaimItemsSelection: 'warranty-claims-items-selection',
  warrantyClaimNew: 'warranty-claims-new',
  warrantyClaimConfirmation: 'warranty-claims-confirmation',
}
