<template>
  <header class="header">
    <div class="header__top-section">
      <a :href="`https://${DOMAIN}`" class="header__logo-link">
        <IconOnLogo
          class="header__logo"
          :class="`header__logo--${color}`"
          :aria-label="$t('global.go_to_homepage')"
      /></a>
      <Button
        class="header__btn"
        tag="a"
        type=""
        :href="`https://${DOMAIN}`"
        :theme="BUTTON_STYLES.theme.outlined"
        :color="color"
        :size="BUTTON_STYLES.size.small"
      >
        {{ $t('back_to_homepage') }}
      </Button>
    </div>
    <nav aria-label="breadcrumbs" class="header__breadcrumbs-nav">
      <ol class="header__breadcrumbs">
        <li class="header__breadcrumb" :class="`header__breadcrumb--${color}`">
          <a :href="`https://${DOMAIN}`">{{ $t('home') }}</a>
        </li>
        <li class="header__breadcrumb" :class="`header__breadcrumb--${color}`">
          {{ $t(pageTitle) }}
        </li>
      </ol>
    </nav>
  </header>
</template>

<script>
import IconOnLogo from '@/components/_icons/IconOnLogo.svg'
import Button, { BUTTON_STYLES } from '@/components/_shared/Button/Button.vue'
import { DOMAIN } from '@/config/paths'

export default {
  name: 'Navigation',
  components: { IconOnLogo, Button },

  props: {
    color: {
      type: String,
      default: 'white',
    },
    pageTitle: {
      type: String,
      default: 'help_center',
    },
  },

  data() {
    return {
      DOMAIN,
      BUTTON_STYLES,
    }
  },
}
</script>

<style scoped lang="scss">
.header {
  &__top-section {
    position: absolute;
    top: 5px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 64px 0 0;

    @include min-screen($mq-larger) {
      top: 20px;
      padding: 0 64px 0 40px;
    }
  }

  &__logo-link {
    &:focus {
      @include focus-style;
    }

    &:focus:not(:focus-visible) {
      @include reset-focus-style;
    }
  }

  &__logo {
    width: 55px;
    height: 55px;
    fill: $color-white;

    &--white {
      fill: $color-white;
    }

    &--black {
      fill: $color-black;
    }

    @include min-screen($mq-larger) {
      width: 70px;
      height: 70px;
    }
  }

  &__breadcrumbs-nav {
    position: absolute;
    top: 10px;
    right: 16px;

    @include min-screen($mq-larger) {
      position: relative;
      margin-bottom: 25px;
      top: initial;
      right: initial;
    }
  }

  &__breadcrumbs {
    @include max-screen($mq-larger - 1) {
      margin-bottom: 50px;
      margin-top: 10px;
    }
  }

  &__breadcrumb {
    position: relative;
    display: inline;
    align-items: center;
    line-height: 1.1;
    font-family: $font-family-On-Semi-Mono;
    font-size: $font-size-smaller;
    text-align: right;
    letter-spacing: 0.12em;
    text-transform: uppercase;

    @include min-screen($mq-larger) {
      text-align: left;
      font-size: $font-size-normal;
      line-height: 1.4;
    }

    &::after {
      display: inline-block;
      width: 4px;
      height: 4px;
      margin: 0 10px 2px 10px; // needs to be centered with the breadcrumbs
      content: '';
      border-radius: 4px;

      @include min-screen($mq-larger) {
        margin: 0 13px 3px 13px; // needs to be centered with the breadcrumbs
      }
    }

    &:last-child::after {
      content: none;
    }

    &--white {
      color: $color-white;
      &::after {
        background-color: $color-white;
      }
    }

    &--black {
      color: $color-black;
      &::after {
        background-color: $color-black;
      }
    }

    a {
      position: relative;
      color: inherit;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background: currentcolor;
        transition: transform 0.2s ease 0s;
        transform: scaleX(0);
        transform-origin: 0 0;
      }

      &.active::after,
      &:hover::after,
      &:focus::after {
        transform: scaleX(1);
      }
    }
  }

  &__btn {
    display: none;

    @include min-screen($mq-larger) {
      display: flex;
      align-items: center;
      width: auto;
    }
  }
}
</style>

<i18n src="@/locales/navigation.json" />
