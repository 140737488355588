import '@/polyfills'
import { newVue } from '@/config/vue'
import router from '@/router'
import Navigation from './Navigation.vue'

document.addEventListener('DOMContentLoaded', () => {
  newVue({
    router,
    props: ['color', 'pageTitle'],
    render: h => h(Navigation, { props: { color: 'black', pageTitle: 'shop_locator' } }),
    mount: '.js-nav',
  })
})
