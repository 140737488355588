<template>
  <button
    :is="tag"
    class="button"
    :class="[
      `button--color-${theme}-${color}`,
      `button--${size}`,
      { 'button--loading': isSubmitting },
      { 'button--hero-banner': isHeroBanner },
    ]"
    :type="type"
    :disabled="isDisabled || isSubmitting"
  >
    <span class="button__inner" :class="{ 'button__inner--transparent': isSubmitting }">
      <slot />
    </span>
  </button>
</template>

<script>
export const BUTTON_STYLES = {
  theme: {
    filled: 'filled',
    outlined: 'outlined',
  },
  color: {
    black: 'black',
    white: 'white',
  },
  size: {
    large: 'large',
    small: 'small',
  },
}

export default {
  name: 'Button',

  props: {
    tag: {
      type: String,
      default: 'button',
    },

    type: {
      type: String,
      default: 'submit',
    },

    theme: {
      type: String,
      default: BUTTON_STYLES.theme.filled,
      validator(value) {
        return Object.values(BUTTON_STYLES.theme).includes(value)
      },
    },

    color: {
      type: String,
      default: BUTTON_STYLES.color.black,
      validator(value) {
        return Object.values(BUTTON_STYLES.color).includes(value)
      },
    },

    size: {
      type: String,
      default: BUTTON_STYLES.size.large,
      validator(value) {
        return Object.values(BUTTON_STYLES.size).includes(value)
      },
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isSubmitting: {
      type: Boolean,
      default: false,
    },

    isHeroBanner: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import './button-settings';

.button {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: $font-size-normal;
  line-height: 1;
  text-align: center;
  border-radius: 40px;
  height: auto;
  min-height: 48px;

  @include min-screen($mq-small) {
    width: 250px;
  }

  &--color-filled-black {
    @include button-color-filled-black;

    &.button--loading {
      @include button-spinner-color-filled-black;
    }
  }

  &--color-filled-white {
    @include button-color-filled-white;

    &.button--loading {
      @include button-spinner-color-filled-white;
    }
  }

  &--color-outlined-black {
    @include button-color-outlined-black;

    &.button--loading {
      @include button-spinner-outlined-black;
    }
  }

  &--color-outlined-white {
    @include button-color-outlined-white;

    &.button--loading {
      @include button-spinner-outlined-white;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    &--transparent {
      opacity: 0;
    }
  }

  &--large {
    padding: 16px 24px;

    @include min-screen($mq-larger) {
      padding: 20px 40px;
    }
  }

  &--small {
    padding: 14px 24px;
  }

  &.button--hero-banner {
    @include button-hero-banner;
  }
}
</style>
