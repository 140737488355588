import Vue from 'vue'
import store from '@/config/vuex'
import VueRouter from 'vue-router'
import { EventBus, EVENTS } from '@/services/event-bus'
import { getLocale } from '@/helpers/locale'
import { ensureSlashAfterLocaleInCurrentUrl } from '@/helpers/paths'
import routes from './routes'
import {
  DOMAIN,
  ORDER_STATUS_PATH,
  ORDER_TRACKER_PATH,
  CUSTOMER_SERVICE_CONTACT_US_PATH,
  DOMAIN_CONTACT_US_PATH,
  CUSTOMER_SERVICE_WARRANTY_CLAIMS_PATH,
  DOMAIN_WARRANTY_CLAIMS_PATH,
} from '@/config/paths'

Vue.use(VueRouter)

// To make sure all pages (including the homepage) have the same router "base"
ensureSlashAfterLocaleInCurrentUrl()

let heroSectionHeight

export function getAppBase() {
  const { locale } = getLocale()
  return locale ? `/${locale}/` : '/'
}

export function scrollToTopOfPage() {
  const { x, y } = getScrollPosition()
  return 'scrollBehavior' in document.documentElement.style
    ? window.scrollTo({
        top: y,
        left: x,
        behavior: 'smooth',
      })
    : window.scrollTo(x, y)
}

function getHeroSectionHeight() {
  return heroSectionHeight || document.querySelector('.hero-section')?.offsetHeight || 0
}

function getScrollPosition() {
  let x = 0
  let y = 0
  if (On.MediaQueries.width.large) {
    const heroHeight = getHeroSectionHeight()
    y = Math.min(window.pageYOffset, heroHeight)
  }
  return { x, y }
}

const router = new VueRouter({
  mode: 'history',
  base: getAppBase(), // base for vue routing (everything beyond base path will be handled by vue router)
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition // Keep saved position when the browser's back and forward button is used
    } else if (to.path !== from.path) {
      return scrollToTopOfPage() // Scroll to top of the screen when a route change happens
    }
  },
})

const isInitialPageLoad = from => from.name === null

function redirectToDomainPath(path) {
  window.location.href = `https://${DOMAIN}/${path}`
}

function redirectToOrderTracker(to) {
  let url =
    to.query.order && to.query.email
      ? `${ORDER_TRACKER_PATH}/${to.query.order}?email=${to.query.email}`
      : ORDER_TRACKER_PATH

  redirectToDomainPath(url)
}

const redirectDepricatedPaths = to =>
  [
    {
      path: `/${ORDER_STATUS_PATH}`,
      redirect: redirectToOrderTracker,
    },
    {
      path: `/${ORDER_TRACKER_PATH}`,
      redirect: redirectToOrderTracker,
    },
    {
      path: `/${CUSTOMER_SERVICE_CONTACT_US_PATH}`,
      redirect: () => redirectToDomainPath(DOMAIN_CONTACT_US_PATH),
    },
    {
      path: `/${CUSTOMER_SERVICE_WARRANTY_CLAIMS_PATH}`,
      redirect: () => redirectToDomainPath(DOMAIN_WARRANTY_CLAIMS_PATH),
    },
  ].forEach(({ path, redirect }) => {
    if (to.path === path) {
      redirect(to)
    }
  })

router.beforeEach((to, from, next) => {
  // Initial page load must not be reported as virtPath

  if (!isInitialPageLoad(from) && On.EXTERNAL_SERVICES_ACTIONS) {
    On.notifyExternalServices({
      action: On.EXTERNAL_SERVICES_ACTIONS.virtPath,
      details: {
        virtPagePath: to.path,
        virtPageTitle: document.title,
      },
    })
  }

  redirectDepricatedPaths(to)

  next()
})

const PAGE_VISITED_KEY = 'isCsPageVisited'
const wasPageVisited = () => !!history.state?.[PAGE_VISITED_KEY]
const markPageAsVisited = () =>
  history.replaceState({ ...history.state, [PAGE_VISITED_KEY]: true }, '', location.href)

router.afterEach((to, from) => {
  store.commit('page/setCurrentPath', { currentPath: to.path })

  if (!isInitialPageLoad(from)) {
    const isHistoryNavigation = wasPageVisited()
    EventBus.$emit(EVENTS.route.afterChange, { isHistoryNavigation })
  }
  markPageAsVisited()
})

export default router
