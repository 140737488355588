import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'unfetch'
import { getGraphqlEndpointUrl } from '@/helpers/paths'

const link = createUploadLink({
  uri: getGraphqlEndpointUrl(),
  fetch,
})
const cache = new InMemoryCache()
const client = new ApolloClient({ link, cache })

export default client
