import { getLocale } from '@/helpers/locale'

export function getGraphqlEndpointUrl() {
  const { locale } = getLocale()
  const localeUrl = locale ? `/${locale}` : ''
  const hostname = window.location.origin

  return `${hostname}${localeUrl}/graphql`
}

export function ensureSlashAfterLocaleInCurrentUrl() {
  const formattedRoute = window.location.href.replace(
    /(https?:\/\/[^/]+\/[a-z]{2}-[a-z]{2})(\/?)/,
    '$1/'
  )
  if (window.location.href !== formattedRoute) {
    window.history.replaceState(window.history.state, '', formattedRoute)
  }
}
